/*!
 * LiteWebChat_Frame 2.2.1 (https://lab.morfans.cn/LiteWebChat_Frame)
 * MorFans Lab(c) 2017-2023
 * Licensed under LGPL
 */@charset "UTF-8";
.lite-chatbox {
  height: calc(100% - 150px);
}
.lite-chatbox > div:last-child {
  margin-bottom: 20px;
}

.lite-chatinput {
  width: 100%;
  height: 150px;
  position: relative;
  bottom: 0px;
  background-color: #fff;
}
[litewebchat-theme=dark] .lite-chatinput {
  background-color: #202223;
}
.lite-chatinput img {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
}
.lite-chatinput .boundary {
  cursor: s-resize;
  margin: 0 auto;
  border-width: 1px 0px 0px 0px;
  border-color: rgba(0, 0, 0, 0.2);
  height: 5px;
  background: #fff;
}
[litewebchat-theme=dark] .lite-chatinput .boundary {
  background: #202223;
}
.lite-chatinput > .chatinput {
  position: relative;
  overflow-y: scroll;
  /* margin: 0px 3px 0px 3px; */
  width: calc(100% - 6px);
  margin: auto;
  /* width: 100%; */
  height: calc(100% - 75px);
  /* height: 100%; */
  border: none;
  outline: none;
  resize: none;
  font-size: 18px;
  color: #373737;
  word-break: break-all;
  overflow-wrap: break-word;
  padding: 5px;
  outline: none;
}
[litewebchat-theme=dark] .lite-chatinput > .chatinput {
  color: #d4d4d4;
}
.lite-chatinput .send {
  float: right;
  padding: 4px 20px 4px 20px;
  margin-right: 12px;
  margin-top: -2px;
  color: white;
  background: linear-gradient(20deg, rgba(63, 143, 225, 0.8) 0%, #44d7c9 100%);
  box-shadow: 5px 5px 15px 0 rgba(102, 102, 102, 0.1);
  border: none;
  border-radius: 4px;
  transition: all 0.2s;
}
[litewebchat-theme=dark] .lite-chatinput .send {
  background: linear-gradient(20deg, rgba(25, 91, 159, 0.8) 0px, rgb(33, 154, 146) 100%);
}
.lite-chatinput .send:hover {
  transform: scale(1.1);
  opacity: 0.7;
}
.lite-chatinput .send:active {
  transform: scale(0.9);
  opacity: 1;
}
.lite-chatinput .tool-button {
  padding: 0px 3px 0px 3px;
  background: none;
  border: none;
  margin: 5px;
  margin-bottom: 0px;
  transition: all 0.2s;
}
.lite-chatinput .tool-button:hover {
  transform: scale(1.1);
  opacity: 0.7;
}
.lite-chatinput .tool-button:active {
  transform: scale(0.9);
  opacity: 1;
}
.lite-chatinput .tool-button path {
  fill: rgb(139, 135, 153);
}
.lite-chatinput .tool-button svg {
  width: 18px;
}

/* 由功能按钮唤起的功能页面 */
.lite-chatbox-tool {
  /* border: 5px solid red; */
  position: absolute;
  /* bottom: 20px; */
  margin-left: 3px;
  z-index: 3;
}

/* 部分功能页面需要用到的遮罩 */
#toolMusk {
  /* border: 3px solid red; */
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}