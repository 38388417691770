@use "sass:selector";

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin push-animate($time: .2, $scaleHover: 1.1, $scalePush: 0.9) {
  transition: all #{$time}s; // 让按下有呼吸感

  &:hover {
    transform: scale($scaleHover);
    opacity: .7;
  }

  &:active {
    transform: scale($scalePush);
    opacity: 1;
  }
}
